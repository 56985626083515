html {
  box-sizing: border-box;
  font-size: 10px;
}
*, *:before, *:after { box-sizing: inherit; }

body { font-family: 'Roboto', sans-serif; }

.container {
  display: flex;
  min-height: 100vh;
  background-color:#d1d3d9;
}
